// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
    color: white;
    text-align: center;
}
form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

/* Label and input styling */
label {
    color: white; /* White text */
    font-size: 18px;
}

input {
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
}

/* Button styling */
button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff; /* Blue button */
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

/* Status message styling */
p {
    color: white;
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/Removal.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;AACb;;AAEA,4BAA4B;AAC5B;IACI,YAAY,EAAE,eAAe;IAC7B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,aAAa;AACjB;;AAEA,mBAAmB;AACnB;IACI,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB,EAAE,gBAAgB;IAC3C,YAAY;IACZ,eAAe;IACf,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB,EAAE,yBAAyB;AACxD;;AAEA,2BAA2B;AAC3B;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":["h1 {\n    color: white;\n    text-align: center;\n}\nform {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 10px;\n}\n\n/* Label and input styling */\nlabel {\n    color: white; /* White text */\n    font-size: 18px;\n}\n\ninput {\n    padding: 10px;\n    border: none;\n    border-radius: 5px;\n    font-size: 16px;\n    outline: none;\n}\n\n/* Button styling */\nbutton {\n    padding: 10px 20px;\n    border: none;\n    border-radius: 5px;\n    background-color: #007bff; /* Blue button */\n    color: white;\n    font-size: 16px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n}\n\nbutton:hover {\n    background-color: #0056b3; /* Darker blue on hover */\n}\n\n/* Status message styling */\np {\n    color: white;\n    font-size: 16px;\n    margin-top: 20px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
