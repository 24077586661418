import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import UserPage from './UserPage';
import NotAuthorizedPage from '../NotAuth'

function UserRoutes({ userId, isAuthenticated, handleLogout }) {
    console.log(userId)
    console.log(isAuthenticated)
    // If user is not authenticated or tries to access another user's page, show Not Authorized
    if (!isAuthenticated) {
        return <NotAuthorizedPage />;
    }

    return (
        <Routes>
        <Route
            path=":user_id/*"
            element={<UserPage handleLogout={handleLogout} />}
        />
        {/* You can add more routes here */}
        <Route path="/" element={<Navigate to={`/user/${userId}`} />} />
        </Routes>
    );
}

export default UserRoutes;
