// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy-container {
  font-family: Arial, sans-serif;
  color: #333; /* Ensures text is not white */
  padding: 40px;
  max-width: 800px;
  margin: auto;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.privacy-policy-container h1 {
  text-align: center;
  font-size: 2em;
  color: #222; /* More specific color for header */
  margin-bottom: 20px;
}

.policy-section {
  margin-top: 20px;
}

.policy-section p {
  line-height: 1.6;
  font-size: 1em;
  color: #333; /* Ensures text within <p> elements is dark */
}
`, "",{"version":3,"sources":["webpack://./src/PrivacyPolicy.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,WAAW,EAAE,8BAA8B;EAC3C,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW,EAAE,mCAAmC;EAChD,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,WAAW,EAAE,6CAA6C;AAC5D","sourcesContent":[".privacy-policy-container {\n  font-family: Arial, sans-serif;\n  color: #333; /* Ensures text is not white */\n  padding: 40px;\n  max-width: 800px;\n  margin: auto;\n  background-color: #f9f9f9;\n  border: 1px solid #ddd;\n  border-radius: 8px;\n}\n\n.privacy-policy-container h1 {\n  text-align: center;\n  font-size: 2em;\n  color: #222; /* More specific color for header */\n  margin-bottom: 20px;\n}\n\n.policy-section {\n  margin-top: 20px;\n}\n\n.policy-section p {\n  line-height: 1.6;\n  font-size: 1em;\n  color: #333; /* Ensures text within <p> elements is dark */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
