import React, { useState } from "react";
import "./Removal.css"

function Removal() {
    const [deviceId, setDeviceId] = useState(""); // State to capture input
    const [status, setStatus] = useState(null);  // State to display response

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload on form submission
        try {
            const response = await fetch(`https://mdm.retvrn.world/api/remove_android/${deviceId}`, {
                method: "GET",
            });
            if (response.ok) {
                setStatus(`200 OK: Device ${deviceId} removed successfully.`);
            } else {
                setStatus(`Error ${response.status}: Device not found.`);
            }
        } catch (error) {
            setStatus("Network error or server unreachable.");
        }
    };

    return (
        <div>
            <h1>Remove Android Device</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="device_id">Enter Android UDID:</label>
                <input
                    type="text"
                    id="device_id"
                    name="device_id"
                    value={deviceId}
                    onChange={(e) => setDeviceId(e.target.value)}
                    placeholder="Enter Android UDID"
                    required
                />
                <button type="submit">Remove</button>
            </form>
            {status && <p>{status}</p>}
        </div>
    );
}

export default Removal;
