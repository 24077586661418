import './style/UserPage.css'
import React, { useState, useEffect } from 'react'; // Import useState and useEffect
import axios from 'axios'; // Import axios for API calls
import { useParams } from 'react-router-dom';
function UserPage({ handleLogout }) {

  const [username, setUsername] = useState('');
  const user_id = 0
  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const response = await axios.get(`https://mdm.retvrn.world/api/user/${user_id}`);
        setUsername(response.data.username);
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    };

    if (user_id) {
      fetchUsername();
    }
  }, [user_id]);
  

  return (
    <div className="user-container">
  
      <div className="header">
        <h1 className="company-name">RETVRN, Inc.</h1>
      </div>

      <div className="sidebar">
        <a href="#">Devices</a>
        <a href="#">Enrollment Information</a>
        <a href="#"></a>
        <a href="#">Help</a>
      </div>

      <div className="main-content">
        <h1>Welcome, User {user_id}!</h1>
        <button onClick={handleLogout}>Logout</button>
      </div>
</div>
  );
}

export default UserPage;