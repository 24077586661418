// PrivacyPolicy.js
import React from "react";
import "./PrivacyPolicy.css"; // Import specific CSS for the privacy policy

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <section>
      <p>RETVRN, Inc. respects the privacy of our customers and collects and stores no information regarding downloads, usage during the application, or information about any of our customers.</p>
      <p>If you have questions about any of these practices, please contact nicholas@retvrn.world</p>
      </section>

    </div>
  );
}

export default PrivacyPolicy;
