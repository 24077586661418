import React from 'react';

function NotAuthorizedPage() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>403 - Not Authorized</h1>
      <p>You do not have access to this page. Please log in first.</p>
    </div>
  );
}

export default NotAuthorizedPage;
