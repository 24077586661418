import './App.css';
import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { login, logout, checkAuth } from './services/authService';
import UserRoutes from './components/User/UserRoutes';
import PrivacyPolicy from "./PrivacyPolicy.js";
import Removal from "./Removal.js"

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      setIsLoading(true);
      const authResult = await checkAuth();
      if (authResult) {
        setIsAuthenticated(true);
        setUserId(authResult.user_id);
        navigate(`/user/${authResult.user_id}`);
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    checkAuthentication();
  }, [navigate]);

  const handleLogin = async (loginUsername, loginPassword) => {
    setIsLoading(true);
    try {
      const response = await login(loginUsername, loginPassword);
      setUserId(response.user_id);
      setIsAuthenticated(true);
      navigate(`/user/${response.user_id}`); 
    } catch (error) {
      setError('Invalid credentials');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    logout();
    setIsAuthenticated(false);
    setError('');
    navigate('/');
  };

  if (isLoading) {
    return (
      <div className="loading-screen">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <Routes>
      {/* Public Route: Privacy Policy */}
      <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      <Route path="/remove_android" element={<Removal />} />
      {/* Login Form Route */}
      <Route
        path="/"
        element={
          isAuthenticated ? (
            <Navigate to={`/user/${userId}`} />
          ) : (
            <div className="login-container">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  const loginUsername = e.target.login.value;
                  const loginPassword = e.target.password.value;
                  handleLogin(loginUsername, loginPassword);
                }}
                className="login-form"
              >
                <h1 className="title-1">SHIFT</h1>
                <h1 className="title-2">Management</h1>
                <input type="text" name="login" placeholder="Email or Username" />
                <input type="password" name="password" placeholder="Password" />
                <button type="submit">Login</button>
                <p className={`error ${error ? "visible" : ""}`}>{error || ""}</p>
                <p className="support-info">For Support, Contact (772) 202-0410</p>
              </form>       
            </div>
          )
        }
      />

      {/* Protected User Routes */}
      <Route
        path="/user/*"
        element={
          isAuthenticated ? (
            <UserRoutes userId={userId} isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
          ) : (
            <Navigate to="/" />
          )
        }
      />
    </Routes>
  );
}

export default App;
