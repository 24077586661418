import axios from 'axios';

const API_URL = 'https://mdm.retvrn.world/api';

export const login = async (username, password) => {
  try {
    const response = await axios.post(`${API_URL}/login`, { username, password });
    if (response.data) {
      localStorage.setItem('user', JSON.stringify(response.data));
      return response.data;
    }
    throw new Error('No token received');
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};

export const logout = async () => {
  const user = getCurrentUser()
  if (user && user.user_id && user.access_token) {
    try {
      localStorage.removeItem('user');
      const response = await axios.post(`${API_URL}/logout`, {
        user_id: user.user_id,
        token: user.access_token
      });

      if (response.data.status === 'valid') {
        // Return the full user object if the token is valid
        return user;  // This user object contains user_id, token, etc.
      } else {
        logout();  // Invalidate session if token is not valid
        return null;  // Return null if token is invalid
      }
    } catch (error) {
      return null;
    }
  }
  return null; 
};

export const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

export const checkAuth = async () => {
  const user = getCurrentUser();
  
  if (user && user.user_id && user.access_token) {
    try {
      const response = await axios.post(`${API_URL}/verify_token`, {
        user_id: user.user_id,
        token: user.access_token
      });

      

      if (response.data.status === 'valid') {
        // Return the full user object if the token is valid
        return user;  // This user object contains user_id, token, etc.
      } else {
        logout();  // Invalidate session if token is not valid
        return null;  // Return null if token is invalid
      }
    } catch (error) {
      console.error('Token verification failed:', error);
      logout();  // Invalidate session if an error occurs
      return null;
    }
  }
  return null;  // No token found, return null
};
