// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PrivacyPolicy.css */
.privacy-policy-container {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 40px;
    max-width: 800px;
    margin: auto;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .privacy-policy-container h1 {
    text-align: center;
    font-size: 2em;
    color: #222;
    margin-bottom: 20px;
  }
  
  .policy-section {
    margin-top: 20px;
  }
  
  .policy-section h2 {
    font-size: 1.5em;
    color: #444;
  }
  
  .policy-section p {
    line-height: 1.6;
    font-size: 1em;
  }
  `, "",{"version":3,"sources":["webpack://./src/PrivacyPolicy.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,cAAc;EAChB","sourcesContent":["/* PrivacyPolicy.css */\n.privacy-policy-container {\n    font-family: Arial, sans-serif;\n    color: #333;\n    padding: 40px;\n    max-width: 800px;\n    margin: auto;\n    background-color: #f9f9f9;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n  }\n  \n  .privacy-policy-container h1 {\n    text-align: center;\n    font-size: 2em;\n    color: #222;\n    margin-bottom: 20px;\n  }\n  \n  .policy-section {\n    margin-top: 20px;\n  }\n  \n  .policy-section h2 {\n    font-size: 1.5em;\n    color: #444;\n  }\n  \n  .policy-section p {\n    line-height: 1.6;\n    font-size: 1em;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
